import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
    consentsEnum,
    DEALER_ROLE,
    ignoreKeyInHTML,
    orderCancelledKey,
    SUPPORT_ROLE,
    TRANSLATIONS_NAMESPACE,
} from '../../constants/global'
import { CardTitle } from '../../components/Wrappers/Card'
import { MediumWidthCard } from '../../components/Wrappers/MediumWidthCard'
import {
    Grid,
    IconButton,
    InputLabel,
    TextField,
    Checkbox,
    FormControl,
    Box,
    Button,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DescriptionIcon from '@material-ui/icons/Description'
import { FormWrapperPadding } from '../../components/FormElements'
import VehicleInfoDialog from '../../components/VehicleInfoDialog'
import {
    CONSENTS_CREATE_DIALOG_STATUS_MAP,
    getDateFormat,
    VEHICLE_INFO_TYPES_ENUM,
} from '../../util/util'
import { DateTime } from 'luxon'
import { setConsentsCreateDialogStatus } from '../../actions/popupsActions'
import { SET_CUSTOMER_DATA } from '../../types/reduxActionTypes'
import { VehicleConsentsDialog } from '../../components/VehicleConsentsDialog'
import { VehicleRemoveDialog } from '../../components/VehicleRemoveDialog'

const year = new Date().getFullYear()

export default ({ customer, vehiclesData, getVehiclesData, getStatusesData }) => {
    const { userInfo } = useSelector((state) => state.oidc)
    const [editModeIndex, setEditModeIndex] = useState(-1)
    const [isAddNewVehicleOpen, setIsAddNewVehicleOpen] = useState(false)
    const [isVehicleRemoveDialogOpenWithVehicleId, setIsVehicleRemoveDialogOpenWithVehicleId] =
        useState(-1)
    const [isVehicleConsentPopupOpenWithConsentId, setIsVehicleConsentPopupOpenWithConsentId] =
        useState(-1)
    const { selectedLanguage } = useSelector((state) => state.options)
    const dateFormat = getDateFormat(selectedLanguage)
    const dispatch = useDispatch()

    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    const handleEditMode = (newIndex) => {
        setEditModeIndex((prevState) => (prevState === newIndex ? -1 : newIndex))
    }

    const onAddVehicleHandle = () => {
        dispatch({
            type: SET_CUSTOMER_DATA,
            payload: {
                customer: {
                    ...customer,
                    summaryData: {
                        [consentsEnum.CONNECT_SERVICES]: false,
                        [consentsEnum.IDENT]: false,
                        [consentsEnum.PSP]: false,
                    },
                    orderDetails: {},
                },
            },
        })
        setConsentsCreateDialogStatus(CONSENTS_CREATE_DIALOG_STATUS_MAP.addVehicle)
    }

    return (
        <>
            <MediumWidthCard>
                {userInfo && userInfo.role === DEALER_ROLE && (
                    <Grid container justifyContent={'flex-end'}>
                        <Grid item>
                            <Button
                                startIcon={<AddIcon />}
                                onClick={onAddVehicleHandle}
                                style={{ minWidth: 'auto' }}
                            >
                                {t('general.button.addVehicle')}
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {vehiclesData &&
                    vehiclesData.map((vehicleData, index) => (
                        <Box mb={index < vehiclesData.length - 1 ? 4 : 0} key={index}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <CardTitle>
                                        {t('general.journey.vehicle')} ({vehicleData.vehicleOrderId}
                                        ) {index + 1}/{vehiclesData.length}
                                    </CardTitle>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        onClick={() =>
                                            setIsVehicleConsentPopupOpenWithConsentId(
                                                vehicleData.consentId
                                            )
                                        }
                                        title={t('general.order.icon.displayConsent', {
                                            replace: ignoreKeyInHTML,
                                        })}
                                    >
                                        <DescriptionIcon />
                                    </IconButton>
                                    {userInfo &&
                                        (userInfo.role === DEALER_ROLE ||
                                            userInfo.role === SUPPORT_ROLE) && (
                                            <>
                                                <IconButton
                                                    color={
                                                        editModeIndex === index
                                                            ? 'primary'
                                                            : 'default'
                                                    }
                                                    onClick={
                                                        vehicleData.vehicleStatus ===
                                                        orderCancelledKey
                                                            ? null
                                                            : () => handleEditMode(index)
                                                    }
                                                    disabled={
                                                        vehicleData.vehicleStatus ===
                                                        orderCancelledKey
                                                    }
                                                    title={t('general.order.icon.editVehicle', {
                                                        replace: ignoreKeyInHTML,
                                                    })}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={
                                                        vehicleData.vehicleStatus ===
                                                        orderCancelledKey
                                                            ? null
                                                            : () =>
                                                                  setIsVehicleRemoveDialogOpenWithVehicleId(
                                                                      vehicleData.vehicleId
                                                                  )
                                                    }
                                                    disabled={
                                                        vehicleData.vehicleStatus ===
                                                        orderCancelledKey
                                                    }
                                                    title={t('general.order.icon.deleteVehicle', {
                                                        replace: ignoreKeyInHTML,
                                                    })}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>
                                        )}
                                </Grid>
                            </Grid>

                            <FormWrapperPadding>
                                <Grid container>
                                    <Grid item md={3}>
                                        <TextField
                                            name={'vehicle-status'}
                                            value={
                                                vehicleData.vehicleStatus
                                                    ? t(
                                                          `general.statuses.vehicle.${vehicleData.vehicleStatus}`,
                                                          { replace: ignoreKeyInHTML }
                                                      )
                                                    : ''
                                            }
                                            label={t('general.customers.table.col.vehicleStatus')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        <TextField
                                            name={'vehicle-status-detail'}
                                            value={`${t(vehicleData.vehicleStatusDetail.key, {
                                                replace: ignoreKeyInHTML,
                                            })} (${
                                                vehicleData.vehicleStatusDetail.value
                                                    ? DateTime.fromJSDate(
                                                          new Date(
                                                              vehicleData.vehicleStatusDetail.value
                                                          )
                                                      ).toFormat(dateFormat)
                                                    : ''
                                            })`}
                                            label={t('general.vehicleStatusDetail')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    {!!vehicleData?.orderStatus?.checkpointCode && (
                                        <Grid item md={3}>
                                            <TextField
                                                name={'order-status'}
                                                value={`${t(
                                                    `general.vehicle.orderStatus.${vehicleData.orderStatus.checkpointCode}`,
                                                    { replace: ignoreKeyInHTML }
                                                )}${
                                                    vehicleData?.orderStatus?.checkpointDate
                                                        ? ` (${DateTime.fromJSDate(
                                                              new Date(
                                                                  vehicleData.orderStatus.checkpointDate
                                                              )
                                                          ).toFormat(dateFormat)})`
                                                        : ''
                                                }`}
                                                label={t('general.vehicle.orderStatus.orderStatus')}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid container>
                                    <Grid item md={3}>
                                        <TextField
                                            name={'vin'}
                                            value={vehicleData.vin ?? ''}
                                            label={t('general.order.vin')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        <TextField
                                            name={'commissionNumber'}
                                            value={vehicleData.commissionNumber ?? ''}
                                            label={t('general.order.commission')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        <TextField
                                            name={'orderNumber'}
                                            value={vehicleData.orderNumber ?? ''}
                                            label={t('general.order.orderNo')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        <TextField
                                            name={'vehicleType'}
                                            value={
                                                vehicleData.vehicleType
                                                    ? t(
                                                          `general.vehicle.vehicleType.${vehicleData.vehicleType.toLowerCase()}`,
                                                          { replace: ignoreKeyInHTML }
                                                      )
                                                    : ''
                                            }
                                            label={t('general.vehicle.vehicleType')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item md={3}>
                                        <TextField
                                            name={'dateOfCreation'}
                                            value={
                                                vehicleData.dateOfCreation
                                                    ? DateTime.fromJSDate(
                                                          new Date(vehicleData.dateOfCreation)
                                                      ).toFormat(dateFormat)
                                                    : ''
                                            }
                                            label={t('general.order.dateOfCreation')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        <TextField
                                            name={'deliveryDateToImporter'}
                                            value={
                                                vehicleData.deliveryDateToImporter
                                                    ? DateTime.fromJSDate(
                                                          new Date(
                                                              vehicleData.deliveryDateToImporter
                                                          )
                                                      ).toFormat(dateFormat)
                                                    : ''
                                            }
                                            label={t('general.order.deliveryDateToImporter')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item md={3}>
                                        <TextField
                                            name={'sales-person'}
                                            value={vehicleData.salesPerson ?? ''}
                                            label={t('general.order.salesPerson')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        <TextField
                                            name={'psp'}
                                            value={vehicleData.preferredServicePartnerName ?? ''}
                                            label={t('general.order.psp')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        <TextField
                                            name={'consent'}
                                            value={`${
                                                vehicleData.consentConfirmationDate
                                                    ? DateTime.fromJSDate(
                                                          new Date(
                                                              vehicleData.consentConfirmationDate
                                                          )
                                                      ).toFormat(dateFormat)
                                                    : ''
                                            }${
                                                vehicleData.consentType
                                                    ? ` (${t(
                                                          `general.order.consentForm.${vehicleData.consentType.toLowerCase()}`,
                                                          { replace: ignoreKeyInHTML }
                                                      )})`
                                                    : ''
                                            }`}
                                            label={t('general.order.consent')}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md>
                                        <FormControl disabled>
                                            <InputLabel style={{ whiteSpace: 'nowrap' }}>
                                                {t('general.vehicle.pspAndDms')}
                                            </InputLabel>
                                            <Checkbox
                                                style={{ marginTop: '24px' }}
                                                name={'pspAndDms'}
                                                checked={!!vehicleData.consents?.[consentsEnum.PSP]}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* MSP-51523 - Hide ident */}
                                    {/*<Grid item md>*/}
                                    {/*    <FormControl disabled>*/}
                                    {/*        <InputLabel style={{ whiteSpace: 'nowrap' }}>*/}
                                    {/*            {t('general.vehicle.ident')}*/}
                                    {/*        </InputLabel>*/}
                                    {/*        <Checkbox*/}
                                    {/*            style={{ marginTop: '24px' }}*/}
                                    {/*            name={'ident'}*/}
                                    {/*            checked={vehicleData.consents[consentsEnum.IDENT]}*/}
                                    {/*        />*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </FormWrapperPadding>
                        </Box>
                    ))}
            </MediumWidthCard>
            {editModeIndex !== -1 && vehiclesData[editModeIndex] && (
                <VehicleInfoDialog
                    actionType={VEHICLE_INFO_TYPES_ENUM.update}
                    customer={customer}
                    vehicleData={{
                        ...vehiclesData[editModeIndex],
                        commissionNumber: `${vehiclesData[editModeIndex]?.commissionNumber ?? ''}-${
                            userInfo.bid ?? ''
                        }-${year}`,
                    }}
                    getVehiclesData={getVehiclesData}
                    getStatusesData={getStatusesData}
                    setOpen={isAddNewVehicleOpen ? setIsAddNewVehicleOpen : setEditModeIndex}
                />
            )}
            <VehicleRemoveDialog
                isVehicleRemoveDialogOpenWithConsentId={isVehicleRemoveDialogOpenWithVehicleId}
                setIsVehicleRemoveDialogOpenWithVehicleId={
                    setIsVehicleRemoveDialogOpenWithVehicleId
                }
                customerId={customer.id}
                getVehicles={getVehiclesData}
                getStatusesData={getStatusesData}
            />
            <VehicleConsentsDialog
                isVehicleConsentPopupOpenWithConsentId={isVehicleConsentPopupOpenWithConsentId}
                setIsVehicleConsentPopupOpenWithConsentId={
                    setIsVehicleConsentPopupOpenWithConsentId
                }
            />
        </>
    )
}
