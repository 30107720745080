import React from 'react'
import styled from 'styled-components'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { checkUrl, CONSENTS_CREATE_DIALOG_STATUS_MAP, isRegistrationProcess } from '../util/util'
import { useTranslation } from 'react-i18next'
import { DEALER_ROLE, TRANSLATIONS_NAMESPACE } from '../constants/global'
import { useSelector } from 'react-redux'
import { useSaveInputChange } from '../hooks/form/useSaveInputChange'
import { setConsentsCreateDialogStatus } from '../actions/popupsActions'

const Menu = styled.div`
    display: flex;
    margin-left: 50px;
    align-items: center;
    flex: 1 0 auto;
`

const NavLink = styled.div`
    text-decoration: none;
    padding: 5px;
    margin-right: 30px;
    text-align: center;
    object-fit: contain;
    font-size: 16px;
    font-weight: ${(props) => (props.active ? 'bold' : '300')};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #4a4a4a;
    &:hover {
        font-weight: bold;
        cursor: pointer;
    }
`

function MainNavLinks({ label, to, type }) {
    const history = useHistory()
    const { setSearchParam } = useSaveInputChange()

    let match = useRouteMatch({
        path: to,
        exact: false,
    })

    const checkAndShowPopupForCreate = () => {
        if (!checkUrl('create')) {
            setConsentsCreateDialogStatus(CONSENTS_CREATE_DIALOG_STATUS_MAP.active)
        }
    }

    const checkAndShowPopupForCustomers = (e) => {
        if (checkUrl('customers')) {
            e.preventDefault()
        } else {
            setSearchParam(null)
            history.push({
                pathname: to,
            })
        }
    }

    return type === 'createNewCustomer' ? (
        <NavLink onClick={checkAndShowPopupForCreate} active={isRegistrationProcess()}>
            {label}
        </NavLink>
    ) : (
        <NavLink onClick={checkAndShowPopupForCustomers} active={match}>
            {label}
        </NavLink>
    )
}

export default function MainMenu() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { userInfo } = useSelector((state) => state.oidc)

    return (
        <Menu>
            <MainNavLinks label={t('general.header.dealershipCustomers')} to={'/customers'} />
            {userInfo && userInfo.role === DEALER_ROLE && (
                <MainNavLinks
                    label={t('general.header.createNewCustomer')}
                    type={'createNewCustomer'}
                />
            )}
        </Menu>
    )
}
