import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
    DEALER_ROLE,
    DPO_ROLE,
    ignoreKeyInHTML,
    SUPPORT_ROLE,
    TRANSLATIONS_NAMESPACE,
} from '../../constants/global'
import {
    EMPTY,
    getErrorText,
    isCustomerInformationValid,
    validateCustomerInformation,
} from '../../util/validators'
import { defaultCountryId, INPUT_LONG } from '../../constants/defaultFormValues'
import { TextInfo, FormWrapperPadding } from '../../components/FormElements'
import RequiredBox from '../../components/RequiredBox'
import { CardTitle } from '../../components/Wrappers/Card'
import { MediumWidthCard } from '../../components/Wrappers/MediumWidthCard'
import DeleteCustomerPopUp from '../../components/PopUp/variants/DeleteCustomerPopUp'
import { Customers } from '../../api'
import { updateErrorMessage, updateSuccessMessage } from '../../types/reduxNotificationTypes'
import { prepareCustomerInformationForUpdate } from '../../util/requestBodyCreators'
import { useDispatchNotification } from '../../hooks/useDispatchNotification'
import { Box, Button, Grid, MenuItem, TextField } from '@material-ui/core'
import { countryPhoneCodes } from '../../constants/countryPhoneCodesConfiguration'

export default function UpdateCustomerInformation({ customer }) {
    const [errors, setErrors] = useState({})
    const { countries, selectedLanguage } = useSelector((state) => state.options)
    const { userInfo } = useSelector((state) => state.oidc)
    const dispatchNotification = useDispatchNotification()

    const [data, setData] = useState({
        ...customer.customerInformation,
    })
    const [editMode, setEditMode] = useState(false)
    const [isDeleteCustomerPopupVisible, setIsDeleteCustomerPopupVisible] = useState(false)

    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    const onUpdateInputHandle = (e) => {
        e.persist()
        setData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onUpdateAddressInputHandle = (e) => {
        e.persist()
        setData((prevState) => ({
            ...prevState,
            address: {
                ...(prevState?.address || {}),
                [e.target.name]: e.target.value,
            },
        }))
    }

    const handleEditMode = useCallback(() => {
        setEditMode(!editMode)
    }, [editMode])

    const onSaveClick = async () => {
        const foundCountry = countries.find((c) => c.id === data?.address?.countryId.toString())

        if (!foundCountry) {
            setErrors({
                address: {
                    countryId: EMPTY,
                },
            })
        }

        const validationErrors = validateCustomerInformation(data)
        setErrors(validationErrors)

        if (isCustomerInformationValid(validationErrors)) {
            try {
                const body = prepareCustomerInformationForUpdate(data)
                await Customers.updateCustomersInformationData(customer.id, body)
                handleEditMode()
                if (!data.phoneNumber) {
                    setData((prevState) => ({
                        ...prevState,
                        dialCode: null,
                    }))
                }
                dispatchNotification(updateSuccessMessage)
            } catch (e) {
                console.error(e)
                dispatchNotification(updateErrorMessage)
            }
        }
    }

    const onCancelEditing = () => {
        setData({ ...customer.customerInformation })
        handleEditMode()
    }

    return (
        <MediumWidthCard>
            <CardTitle>{t('general.customer.title')}</CardTitle>
            <FormWrapperPadding>
                <form noValidate autoComplete="off">
                    <TextField
                        name={'email'}
                        label={t('general.customer.email')}
                        value={data.email}
                        fullWidth
                        disabled
                        required={editMode && !customer.skodaConnectExistsFlag}
                    />
                    <TextField
                        onChange={onUpdateInputHandle}
                        name={'firstName'}
                        label={t('general.customer.firstName')}
                        value={data.firstName || ''}
                        error={!!errors.firstName}
                        helperText={
                            errors.firstName && (
                                <>
                                    {t('general.customer.firstName')}{' '}
                                    {t(getErrorText(errors.firstName))}
                                </>
                            )
                        }
                        disabled={!editMode || customer.skodaConnectExistsFlag}
                        inputProps={{ maxLength: INPUT_LONG }}
                        required={editMode && !customer.skodaConnectExistsFlag}
                    />
                    <TextField
                        onChange={onUpdateInputHandle}
                        name={'lastName'}
                        label={t('general.customer.lastName')}
                        value={data.lastName || ''}
                        error={!!errors.lastName}
                        helperText={
                            errors.lastName && (
                                <>
                                    {t('general.customer.lastName')}{' '}
                                    {t(getErrorText(errors.lastName))}
                                </>
                            )
                        }
                        disabled={!editMode || customer.skodaConnectExistsFlag}
                        inputProps={{ maxLength: INPUT_LONG }}
                        required={editMode && !customer.skodaConnectExistsFlag}
                    />
                    <TextField
                        onChange={onUpdateInputHandle}
                        name={'middleName'}
                        label={t('general.customer.middleName')}
                        value={data.middleName || ''}
                        disabled={!editMode || customer.skodaConnectExistsFlag}
                        inputProps={{ maxLength: INPUT_LONG }}
                    />
                    <TextField
                        onChange={onUpdateInputHandle}
                        name={'nickname'}
                        label={t('general.customer.nickname')}
                        value={data.nickname || ''}
                        error={!!errors.nickname}
                        helperText={
                            errors.nickname && (
                                <>
                                    {t('general.customer.nickname')}{' '}
                                    {t(getErrorText(errors.nickname))}
                                </>
                            )
                        }
                        disabled={!editMode || customer.skodaConnectExistsFlag}
                        inputProps={{ maxLength: INPUT_LONG }}
                        required={editMode && !customer.skodaConnectExistsFlag}
                    />
                    <Grid container>
                        <Grid item sm={6}>
                            <TextField
                                select
                                name={'countryId'}
                                onChange={(e) => {
                                    onUpdateAddressInputHandle(e)
                                    const defaultCountryLanguage = countries.find(
                                        (item) => e.target.value === item.id.toString()
                                    )?.defaultLanguage
                                    defaultCountryLanguage &&
                                        setData((prevState) => ({
                                            ...prevState,
                                            preferredLanguage: defaultCountryLanguage,
                                        }))
                                }}
                                label={t('general.customer.country')}
                                value={
                                    !!data.address
                                        ? customer.skodaConnectExistsFlag &&
                                          data.address.countryId === '0'
                                            ? '-'
                                            : data.address.countryId !== undefined &&
                                              data.address.countryId !== defaultCountryId
                                            ? data.address.countryId
                                            : defaultCountryId
                                        : defaultCountryId
                                }
                                error={!!errors.address && errors.address.countryId}
                                helperText={
                                    errors.address?.countryId && (
                                        <>
                                            {t('general.customer.country')}{' '}
                                            {t(getErrorText(errors.address.countryId))}
                                        </>
                                    )
                                }
                                disabled={
                                    !editMode ||
                                    customer.skodaConnectExistsFlag ||
                                    !data?.address?.countryId ||
                                    data.address.countryId === '0'
                                }
                                required={editMode && !customer.skodaConnectExistsFlag}
                            >
                                {[
                                    {
                                        value: '0',
                                        label: t('general.select.choose'),
                                        disabled: true,
                                    },
                                    ...countries
                                        .map((c) => ({
                                            value: c.id,
                                            label: t(`general.country.${c.countryCode}`),
                                            countryNameTranslated: t(
                                                `general.country.${c.countryCode}`,
                                                {
                                                    replace: ignoreKeyInHTML,
                                                }
                                            ),
                                        }))
                                        .sort((a, b) =>
                                            a.countryNameTranslated.localeCompare(
                                                b.countryNameTranslated,
                                                selectedLanguage.substring(0, 2)
                                            )
                                        ),
                                ].map((item) => (
                                    <MenuItem
                                        value={item.value}
                                        key={item.value}
                                        disabled={item.disabled}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item sm={6}>
                            {data?.address?.countryId && countries && (
                                <TextField
                                    select
                                    name={'preferredLanguage'}
                                    onChange={onUpdateInputHandle}
                                    label={t('general.customer.preferredLanguage')}
                                    value={data.preferredLanguage || ''}
                                    error={!!errors.preferredLanguage}
                                    helperText={
                                        errors.preferredLanguage && (
                                            <>
                                                {t('general.customer.preferredLanguage')}{' '}
                                                {t(getErrorText(errors.preferredLanguage))}
                                            </>
                                        )
                                    }
                                    disabled={!editMode || customer.skodaConnectExistsFlag}
                                    required={editMode && !customer.skodaConnectExistsFlag}
                                >
                                    {[
                                        {
                                            value: '',
                                            label: t('general.select.choose'),
                                            disabled: true,
                                        },
                                        ...(countries
                                            .find(
                                                (country) =>
                                                    country.id.toString() ===
                                                    (data.address?.countryId || '').toString()
                                            )
                                            ?.languages?.map((item) => ({
                                                value: item.toString(),
                                                label: t(`general.language.${item}`),
                                            })) || []),
                                    ].map((item) => (
                                        <MenuItem
                                            value={item.value}
                                            key={item.value}
                                            disabled={item.disabled}
                                        >
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        </Grid>
                    </Grid>
                    {((customer.skodaConnectExistsFlag && data.dialCode) ||
                        !customer.skodaConnectExistsFlag) && (
                        <TextField
                            select
                            name={'dialCode'}
                            onChange={onUpdateInputHandle}
                            label={t('general.customer.countryCode')}
                            value={data.dialCode || ''}
                            SelectProps={{
                                onOpen: data.dialCode
                                    ? null
                                    : () => {
                                          const userDialCode =
                                              data.address?.countryId &&
                                              countries.find(
                                                  (item) =>
                                                      data.address?.countryId === item.id.toString()
                                              )?.dialCode
                                          userDialCode &&
                                              setData((prevState) => ({
                                                  ...prevState,
                                                  dialCode: userDialCode,
                                              }))
                                      },
                            }}
                            error={!!errors.dialCode}
                            helperText={
                                errors.dialCode && (
                                    <>
                                        {t('general.customer.countryCode')}{' '}
                                        {t(getErrorText(errors.dialCode))}
                                    </>
                                )
                            }
                            disabled={!editMode || customer.skodaConnectExistsFlag}
                            required={editMode && !!data.phoneNumber}
                        >
                            {[
                                {
                                    value: '',
                                    label: t('general.select.choose'),
                                },
                                ...countryPhoneCodes
                                    .map((item) => ({
                                        value: item.dial_code,
                                        label: `(${item.dial_code}) ${t(
                                            `general.country.${item.code}`,
                                            { replace: ignoreKeyInHTML }
                                        )}`,
                                        countryNameTranslated: t(`general.country.${item.code}`, {
                                            replace: ignoreKeyInHTML,
                                        }),
                                    }))
                                    .sort((a, b) =>
                                        a.countryNameTranslated.localeCompare(
                                            b.countryNameTranslated,
                                            selectedLanguage.substring(0, 2)
                                        )
                                    ),
                            ].map((item, index) => (
                                <MenuItem value={item.value} key={index}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    <TextField
                        name={'phoneNumber'}
                        onChange={onUpdateInputHandle}
                        label={t('general.customer.phoneNumber')}
                        value={data.phoneNumber || ''}
                        disabled={!editMode || customer.skodaConnectExistsFlag}
                        error={!!errors.phoneNumber}
                        helperText={
                            errors.phoneNumber && (
                                <>
                                    {t('general.customer.phoneNumber')}{' '}
                                    {t(getErrorText(errors.phoneNumber))}
                                </>
                            )
                        }
                    />
                    {editMode && !customer.skodaConnectExistsFlag && (
                        <RequiredBox withTop withBottom />
                    )}
                    {userInfo &&
                        (userInfo.role === DEALER_ROLE || userInfo.role === SUPPORT_ROLE) &&
                        !editMode &&
                        !customer.skodaConnectExistsFlag && (
                            <Box mt={4} display={'flex'} justifyContent={'center'}>
                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={setEditMode}
                                >
                                    {t('general.button.order.update')}
                                </Button>
                            </Box>
                        )}
                    {!editMode && customer.skodaConnectExistsFlag && (
                        <TextInfo>{t('general.customer.groupid.exists')}</TextInfo>
                    )}
                    {editMode && !customer.skodaConnectExistsFlag && (
                        <Grid container direction={'column'} spacing={2} alignItems={'center'}>
                            <Grid item>
                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={onSaveClick}
                                >
                                    {t('general.save')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={onCancelEditing}>
                                    {t('general.button.cancel')}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {userInfo &&
                        !editMode &&
                        (userInfo.role === DEALER_ROLE ||
                            userInfo.role === DPO_ROLE ||
                            userInfo.role === SUPPORT_ROLE) && (
                            <Box mt={2} display={'flex'} justifyContent={'center'}>
                                <Button onClick={() => setIsDeleteCustomerPopupVisible(true)}>
                                    {t('general.customer.information.deleteCustomer')}
                                </Button>
                                <DeleteCustomerPopUp
                                    open={isDeleteCustomerPopupVisible}
                                    setOpen={setIsDeleteCustomerPopupVisible}
                                    customerId={customer.id}
                                />
                            </Box>
                        )}
                </form>
            </FormWrapperPadding>
        </MediumWidthCard>
    )
}
